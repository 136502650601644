import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import Search from '../Search/Search';
import {Navbar, Nav, NavItem, NavbarBrand} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'


const Header = () => (
  <StaticQuery
  query={graphql`
  query SearchTestIndexQuery {
    siteSearchIndex {
      index
    }
  }
`}
  render={data => (
    <header>
      <Navbar border="dark" bg="light">
        {/* <NavbarBrand>Tom from Accounting</NavbarBrand> */}
        <Nav className="ml-auto shadow-none nav-horizontal">
          <NavItem><a className="text-dark h2 mr-3" href="https://www.instagram.com/tfa_comics/" title="Follow me on Instagram" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></NavItem>
          <NavItem><a className="text-dark h2" href="https://twitter.com/tfa_comics" title="Follow me on Twitter" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></NavItem>
          {/* <Nav.Link href={'/about'}>About</Nav.Link> */}
          {/* <Search searchIndex={data.siteSearchIndex.index}/> */}
        </Nav>
    </Navbar>
  </header>
  )}
  />
);
export default Header;
