import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="mt-5 text-right h5 px-3 text-center">
        © {new Date().getFullYear()}{' '}
      </footer>
    );
  }
}

export default Footer;
