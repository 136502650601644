import React from 'react';
import { BackTop } from 'antd';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { Row, Col, Container } from 'react-bootstrap';

class Layout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Container style={{maxWidth:"1200px"}}>
          <Header />
          <main className="px-3">
            <Row>
              <Col>{children}</Col>
            </Row>
          </main>
          <Footer />
        </Container>
      </React.Fragment>
    );
  }
}

export default Layout;
